<template>
  <div v-frag>
    <v-row class='px-6 mb-8'>
      <v-card
        :loading='isLoading'
        class='mt-8 mr-4 d-flex flex-row'
        height='130px'
        width='48%'
      >
        <v-icon class='primary white--text px-2' size='60'>
          mdi-account-group-outline
        </v-icon>
        <v-col md='3' class='px-0 d-flex flex-column align-center justify-center'>
          <h1 class='success--text'>{{ registeredCard }}%</h1>
        </v-col>
        <v-col class='px-0 d-flex flex-column align-center justify-center'>
          <h2>Employees Registered to Vote</h2>
        </v-col>
      </v-card>
      <v-card
        :loading='isLoading'
        class='mt-8 d-flex flex-row'
        height='130px'
        width='48%'
      >
        <v-icon class='primary white--text px-2' size='60'>
          mdi-archive-arrow-down-outline
        </v-icon>
        <v-col md='3' class='px-0 d-flex flex-column align-center justify-center'>
          <h1 class='success--text'>{{ votedCard }}%</h1>
        </v-col>
        <v-col class='px-0 d-flex flex-column align-center justify-center'>
          <h2>Employees Voted in Selected Election</h2>
        </v-col>
      </v-card>
    </v-row>

    <v-card
      :loading='isLoading'
      class='mx-3 px-6 mt-10'
    >
      <v-row align='center' class='px-4 py-6' justify='space-between'>
        <h3>
          {{ sections.change_election_voting || 'Change election title' }}
          <span class='ml-2 primary--text font-weight-bold text-capitalize'>
            {{ selectedElection.description }}
          </span>
        </h3>
        <v-btn
          :disabled='isLoading'
          class='text-capitalize font-weight-bold'
          color='primary'
          outlined
          @click='showElectionDialog = true'
        >
          Change Election
        </v-btn>
      </v-row>
    </v-card>

    <v-row class='mt-4 px-4'>
      <v-col cols='6'>
        <v-card
          :loading='isLoading'
          class='pt-6 pb-16'
        >
          <v-card-text class='d-flex flex-column align-center'>
            <v-card-title>{{ sections.election_cycle || 'Election cycle title' }}</v-card-title>
            <v-card-subtitle class='mr-6 text-capitalize'>{{ selectedElection.description }}</v-card-subtitle>
          </v-card-text>
          <GChart
            v-if='hasStatistics'
            :data='pieChartData'
            :options='pieChartOptions'
            type='PieChart'
          />
          <div
            v-else
            class='py-16'
          >
            <h2 class='secondary--text text-center px-8 py-10'>
              There are no election statistics available for this company or
              voting campaign, try selecting another one.
            </h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols='6'>
        <v-card class='py-6'>
          <v-card-text class='d-flex flex-column align-center'>
            <v-card-title>{{ sections.key_dates || 'Key dates title' }}</v-card-title>
          </v-card-text>
          <v-data-table
            :loading='isLoading'
            :headers='keyDatesHeaders'
            :items='keyDates'
            class='my-table mx-6 elevation-1'
          >
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols='12'>
        <v-card class='py-6' width='100%'>
          <v-row align='center' class='px-8 my-5' justify='space-between'>
            <h3 class='px-6'>{{ sections.communication_plan || 'Communication Plan title' }}</h3>
            <v-btn
              v-if='isMemberLogged'
              class='text-capitalize font-weight-bold mr-6'
              color='primary'
              outlined
              @click='onCommsClick'
            >
              Communications Dashboard
            </v-btn>
          </v-row>
          <v-data-table
            :loading='isLoading'
            :headers='communicationHeaders'
            :items='communications'
            class='my-table mx-6'
          >
            <template #[`item.content`]='{ item }'>
              <div class='text-truncate' style='max-width: 200px'>{{ item.content }}</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model='showElectionDialog'
      max-width='500px'
      persistent
    >
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-4'>
          <v-icon class='mr-4 white--text' color='primary' large left>mdi-home-outline</v-icon>
          Available Election Information
        </v-card-title>
        <v-card-subtitle>
          <b>Select one campaign from the campaign list below to visualize the election's statistics:</b>
        </v-card-subtitle>
        <v-radio-group
          v-model='selectedElectionId'
          class='ml-6'
        >
          <v-radio
            v-for='campaign in elections'
            :key='campaign.id'
            :label='campaign.description'
            :value='campaign.id'
            class='pb-2'
          ></v-radio>
        </v-radio-group>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            :disabled='isLoading'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            outlined
            @click='showElectionDialog = false'
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled='isLoading'
            :loading='isLoading'
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='onChangeElection'
          >
            Change
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { mapGetters, mapState } from 'vuex'
import memberService from '../../services/member/index'
import employeeService from '../../services/employee/index'

export default {
  components: { GChart },
  data() {
    return {
      showElectionDialog: false,
      selectedElectionId: Number,
      hasStatistics: true,
      isLoading: false,
      selectedElection: {},
      elections: [],
      registeredCard: '0',
      votedCard: '0',
      pieChart: [],
      pieChartData: [
        ['Vote Status', 'Percentage', {type: 'string', role: 'tooltip'}],
        ['Registered & Voted', 0, ``],
        ['Registered & Has Not Voted', 0, ''],
        ['Not Registered', 0, ''],
        ['Ineligible', 0, '']
      ],
      pieChartOptions: {
        height: 400,
        width: '100%',
        chartArea: {
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 100
        },
        colors: ['mediumblue', 'gold', 'firebrick', 'darkgreen'],
        fontSize: 14,
        fontName: 'roboto',
        pieHole: 0.5,
        legend: {
          position: 'bottom',
          width: '100%'
        },
        pieSliceText: 'none',
        annotations: {
          style: 'none',
        },
        backgroundColor: {}
      },
      keyDatesHeaders: [
        { text: 'Date', align: 'start', sortable: false, value: 'date', divider: true, width: '100px' },
        { text: 'Topic', align: 'start', sortable: false, value: 'description', divider: true, width: '400px' }
      ],
      keyDates: [],
      communicationHeaders: [
        { text: 'Topic', align: 'start', value: 'topic', divider: true },
        { text: 'Date', align: 'start', value: 'date', divider: true },
        { text: 'Content', align: 'start', value: 'content', divider: true, width: 200 },
        { text: 'Type', align: 'start', value: 'type', divider: true },
        { text: 'Distribution', align: 'start', value: 'distribution', divider: true }
      ],
      communications: [
        {
          description: 'Candidate Filling Deadline',
          date: '2021-05-03',
          content:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi aperiam atque laboriosam maiores rerum sint ad sequi incidunt excepturi non! Quae qui nisi distinctio ipsa accusantium natus autem ipsam deserunt.',
          type: 'SMS, Email',
          distribution: 'Leadership'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections
    }),
    ...mapGetters({
      user: 'user',
      election: 'election'
    }),
    isMemberLogged() {
      return !!(this.user.profile_id.member_id && !this.user.profile_id.employee_id)
    }
  },
  watch: {
    selectedElection(newSelectedElection) {
      this.$store.dispatch('election', newSelectedElection)
    }
  },
  async created() {
    await this.initialize()
  },
  methods: {
    async initialize() {
      this.isLoading = true
      await this.getElections()
      if (this.isMemberLogged)
        await Promise.allSettled([
          this.memberGetVotingStatisticsCards(),
          this.memberGetVotingStatisticsElectionData(),
          this.memberGetScheduledCommunicationPlan()
        ])
      if (this.user.profile_id.employee_id)
        await Promise.allSettled([
          this.employeeGetVotingStatisticsCards(),
          this.employeeGetVotingStatisticsElectionData(),
          this.employeeGetScheduledCommunicationPlan()
        ])
      this.isLoading = false
    },
    async getElections() {
      const memberId = this.user.profile_id.member_id
      try {
        this.elections = await memberService.elections(memberId)
        if (Object.keys(this.election).length === 0 || this.elections.every(el => this.election.id !== el.id)) {
          this.selectedElection = this.elections[0]
          this.selectedElectionId = this.selectedElection.id
        } else {
          this.selectedElection = this.election
          this.selectedElectionId = this.election.id
        }
      } catch (err) {
        console.log(err)
      }
    },
    async onChangeElection() {
      this.isLoading = true
      if (this.isMemberLogged)
        await Promise.allSettled([
          this.memberGetVotingStatisticsCards(),
          this.memberGetVotingStatisticsElectionData(),
          this.memberGetScheduledCommunicationPlan()
        ])
      if (this.user.profile_id.employee_id)
        await Promise.allSettled([
          this.employeeGetVotingStatisticsCards(),
          this.employeeGetVotingStatisticsElectionData(),
          this.employeeGetScheduledCommunicationPlan()
        ])
      this.selectedElection = this.elections.find(el => el.id == this.selectedElectionId)
      this.showElectionDialog = false
      this.isLoading = false
    },
    onCommsClick() {
      if (this.isMemberLogged) {
        this.$router.push('/communication')
      }
    },
    // MEMBER LOGGED OPERATIONS

    async memberGetVotingStatisticsCards() {
      const memberId = this.user.profile_id.member_id
      try {
        const { registred, voted } = await memberService.votingStatisticsCards({
          memberId,
          electionId: this.selectedElectionId
        })
        this.registeredCard = registred
        this.votedCard = voted
      } catch (err) {
        console.log(err)
      }
    },
    async memberGetVotingStatisticsElectionData() {
      const memberId = this.user.profile_id.member_id
      try {
        const { cycleVotingRecord, keyDates } = await memberService.votingStatisticsElectionData({
          memberId,
          electionId: this.selectedElectionId
        })
        const pieChartData = cycleVotingRecord
        let nullDataCount = 0
        pieChartData.forEach(element => {
          if (element[1] == 0)
            nullDataCount++
        })
        if (nullDataCount === 4) {
          this.hasStatistics = false
        } else {
          this.hasStatistics = true
          this.pieChartData = [
            ['Vote Status', 'Percentage', {type: 'string', role: 'tooltip'}],
            ['Registered & Voted', 0, ``],
            ['Registered & Has Not Voted', 0, ``],
            ['Not Registered', 0, ``],
            ['Ineligible', 0, ``]
          ]
          this.pieChart = cycleVotingRecord
          this.pieChartData[1][1] = this.pieChart[1][1]  // voted
          this.pieChartData[2][1] = this.pieChart[0][1]  // registrated
          this.pieChartData[3][1] = this.pieChart[2][1]  // not_registrated
          this.pieChartData[4][1] = this.pieChart[3][1]  // inelegible
          const percentageRegisteredAndVoted = this.pieChart[1][1]/(this.pieChart[0][1] + this.pieChart[1][1]) * 100
          const percentageRegisteredAndHasNotVoted = this.pieChart[0][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
          const percentageNotRegistered = this.pieChart[2][1]/(this.pieChart[0][1] + this.pieChart[2][1]) * 100
          const percentageIneligible = this.pieChart[3][1]/(this.pieChart[0][1] + this.pieChart[2][1]  + this.pieChart[3][1]) * 100
          this.pieChartData[1][2] = `Registered & Voted: \n ${this.pieChart[1][1]} (${percentageRegisteredAndVoted.toFixed(2)}%)`
          this.pieChartData[2][2] = `Registered & Has Not Voted: \n ${this.pieChart[0][1]} (${percentageRegisteredAndHasNotVoted.toFixed(2)}%)`
          this.pieChartData[3][2] = `Not Registered: \n ${this.pieChart[2][1]} (${percentageNotRegistered.toFixed(2)}%)`
          this.pieChartData[4][2] = `Ineligible: \n ${this.pieChart[3][1]} (${percentageIneligible.toFixed(2)}%)`
          this.keyDates = keyDates
        }
      } catch (err) {
        console.log(err)
      }
    },
    async memberGetScheduledCommunicationPlan() {
      const memberId = this.user.profile_id.member_id
      try {
        this.communications = await memberService.scheduledCommunicationPlan(memberId)
      } catch (err) {
        console.log(err)
      }
    },

    // EMPLOYEE LOGGED OPERATIONS
    async employeeGetVotingStatisticsCards() {
      const employeeId = this.user.profile_id.employee_id
      try {
        const { registred, voted } = await employeeService.votingStatisticsCards({
          employeeId,
          electionId: this.selectedElectionId
        })
        this.registeredCard = registred
        this.votedCard = voted
      } catch (err) {
        console.log(err)
      }
    },
    async employeeGetVotingStatisticsElectionData() {
      const employeeId = this.user.profile_id.employee_id
      try {
        const { cycleVotingRecord, keyDates } = await employeeService.votingStatisticsElectionData({
          employeeId,
          electionId: this.selectedElectionId
        })
        const pieChartData = cycleVotingRecord
        let nullDataCount = 0
        pieChartData.forEach(element => {
          if (element[1] == 0)
            nullDataCount++
        })
        if (nullDataCount === 4) {
          this.hasStatistics = false
        } else {
          this.hasStatistics = true
          this.pieChartData = [
            ['Vote Status', 'Percentage'],
            ['Registered & Voted', 0],
            ['Registered & Has Not Voted', 0],
            ['Not Registered', 0],
            ['Inelegible', 0]
          ]
          this.pieChart = cycleVotingRecord
          this.pieChartData[1][1] = this.pieChart[1][1]
          this.pieChartData[2][1] = this.pieChart[0][1]
          this.pieChartData[3][1] = this.pieChart[2][1]
          this.pieChartData[4][1] = this.pieChart[3][1]
          this.keyDates = keyDates
        }
      } catch (err) {
        console.log(err)
      }
    },
    async employeeGetScheduledCommunicationPlan() {
      const employeeId = this.user.profile_id.employee_id
      try {
        this.communications = await employeeService.scheduledCommunicationPlan(employeeId)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang='scss'>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
